import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import request from '@/utils/request'
import auth from '@/utils/auth'

Vue.config.productionTip = false

// 导入vant所有组件
import Vant from 'vant'
import 'vant/lib/index.css'
Vue.use(Vant)

// 导入vue-wechat-title
import VueWechatTitle from 'vue-wechat-title'
Vue.use(VueWechatTitle)


import VideoPlayer from 'vue-video-player';
const w: any = window
w.videojs = VideoPlayer.videojs
import 'video.js/dist/video-js.css';
import 'vue-video-player/src/custom-theme.css';
require('vue-video-player/src/custom-theme.css')
require('video.js/dist/video-js.min.css')
require('video.js/dist/lang/zh-CN.js')

Vue.use(VideoPlayer)


import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css'
Vue.use(ViewUI)

const authH5 = async () => {
    if (store.state.token) {
      const refreshed = await auth.refreshToken()
      if(refreshed) return
    }
    await auth.wxAuthorize()
}

const initData = async () => {
   // 初始化区域列表
   await request.get('/api/sys/area/children/431000').then(res => store.commit('setAreaList', res))
   // 若区域ID为空，还需查询定制信息初始化定制的区域ID
   if(!store.state.areaId) await request.get('/api/tobacco/rest_msg_set/info').then(res => store.commit('setAreaId', res.areaId))
   // 初始化当前用户信息
   await request.get('/api/account/rest_account').then(res => store.commit('setCurrent', res))
   // 若已关联管理人员（专家、种植户或合作社），还需初始管理人员信息
   const { role,headimgurl } = store.state.current
   if(role && role>=1 && role<=3) await request.get(`/api/tobacco/rest_rel/${role}`).then(res => store.commit('setManagerInfo', res))
    if(headimgurl === ''){
        auth.wxUserInfo()
    }
}

const init = async () => {
  await authH5()
  await initData()
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
}
init()
