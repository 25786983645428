import store from '@/store'
import storage from '@/store/storage'
import router from '@/router'

import { WECHAT_APPID } from '@/utils/constants'
import request from '@/utils/request'
import { Notify } from 'vant'

function _getUrlParams(url: string) {
	if (url.indexOf('?') <=0 ) return {}
	url = url.substr(url.indexOf('?')+1)
	const urlSplit = url.split('&')
	// 获取全部参数及其值
	const result: any = {}
	for(let i=0;i<urlSplit.length;i++) {
		const info = urlSplit[i].split('=')
		result[info[0]] = decodeURI(info[1])
	}
	return result
}
const wxAuthorize = (link?: string) => {
	// 已经授权登录过的就不用再授权了
	if (store.state.token) return
	if(!link) link = window.location.href
	if(link.indexOf('localhost')>0) {
		return console.error('未登录')
	}
	const needReg = storage.get('needReg')
	const params = _getUrlParams(link)  // 地址解析
	if (params.code) {
		if(needReg) wxReg(params.code, link)
		else wxAuth(params.code, link)
	} else {
		const uri = encodeURIComponent(link)
		const authURL = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${WECHAT_APPID}&redirect_uri=${uri}&response_type=code&scope=${needReg?'snsapi_userinfo':'snsapi_base'}&state=123#wechat_redirect`
		window.location.href = authURL
	}
}
const wxAuth = (code: string, link: string) => {
	return request.post(`/api/account/office_account/login/${code}`, null)
	.then(token => {
		store.commit('login', token )
		//因授权为异步操作，在登录成功后再做一次跳转，确保token已放至storage
		window.location.href = link
	}).catch(error => {
		console.error(error)
		//未注册
		if(error.code===1003) {
			storage.set('needReg', true)
			if(link.indexOf('[object%20Object]')>=0) link = link.substring(0, link.indexOf('[object%20Object]'))
			if(link.indexOf('code=')>=0) link = link.substring(0, link.indexOf('code=')-1)
			if(link.indexOf('state=')>=0) link = link.substring(0, link.indexOf('state=')-1)
			wxAuthorize(link)
		}
		router.push('/welcome')
		alert('关注公众帐号，接收更多消息')
	})
}
const wxReg = (code: string, link: string) => {
	return request.post(`/api/account/office_account/reg_and_login/${code}`, null)
	.then(token => {
		store.commit('login', token )
		//因授权为异步操作，在登录成功后再做一次跳转，确保token已放至storage
		window.location.href = link
		storage.remove('needReg')
	}).catch(error => console.log(error))
}

//显式调用获取用户信息
const wxUserInfo = (link?: string) => {
	if(!link) link = window.location.href
	if(link.indexOf('localhost')>0) {
		return console.error('未登录')
	}
	const params = _getUrlParams(link)  // 地址解析
	if (params.code) {
		wxReg(params.code, link)
	} else {
		const uri = encodeURIComponent(link)
		const authURL = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${WECHAT_APPID}&redirect_uri=${uri}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
		window.location.href = authURL
	}
}

const refreshToken = () => {
	return request.get(`/api/account/rest_account/refresh`, null)
	.then(token => {
		store.commit('login', token )
		return true
	})
	.catch(error => {
		console.error(error)
		store.commit('logout')
		return false
	})
}
const requestCurrent = () => {
	return request.get('/api/account/rest_account')
	.then(res => {
		store.commit('setCurrent', res)
		return true
	})
	.catch(error => {
		console.error(error)
		store.commit('logout')
		return false
	})
}
export default { wxAuthorize, refreshToken, requestCurrent,wxUserInfo }
