// 气象站点Id
export const STATION_ID = '57972'
// 郴州国家站
// 经度
export const LON = 113.03
// 纬度
export const LAT = 25.8
export const ADMINCODE = 431000

export const WECHAT_APPID = 'wx5cdc04780511bbbe'
